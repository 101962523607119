export enum AttachType {
  PHOTO = 'photo',
  LINK = 'link',
  VIDEO = 'video',
  TIPS = 'tips',
}

export interface AttachItem {
  type: AttachType;
  photo?: AttachPhoto;
  video?: AttachVideo;
  link?: AttachLink;
  tips?: AttachTips;
}

export interface AttachPhoto {
  isVideo?: boolean;
  photoMedium: string;
  photoLarge: string;
  width: number;
  height: number;
  photoId: number;
  thumbnail?: string;
  photoMediumEncrypted?: string;
  photoLargeEncrypted?: string;
  photoFile?: File;
}

export interface VideoSourceItem {
  resolution: number;
  path: string;
}

export type VideoSource = VideoSourceItem[];

export interface AttachVideo {
  photoMedium: string;
  photoLarge: string;
  width: number;
  height: number;
  videoId: number;
  encrypted?: boolean;
  source: string;
  duration?: number;
}

export interface AttachUploadData {
  bucket: string;
  header: Headers;
  key: string;
  publicUrl: string;
  url: string;
  width: number;
  height: number;
  duration: number;
}

export interface AttachUploadingVideo {
  video: File | null;
  loaded: number;
  total: number;
  isLoaded: boolean;
  isCancelled: boolean;
  pickerId: string;
  uploadData?: AttachUploadData;
}

export interface AttachLink {
  type: string;
  object: any;
  extra?: any;
}

export interface AttachTips {
  id: number;
  fromId: number;
  toId: number;
  amount: number;
  currency: string;
}


export enum MessageTelegramStatus {
  Sending = 0,
  Received = 1,
  Failed = 2,
}

export interface Message {
  id: number;
  fromId: number;
  toId: number;
  text: string;
  attachments?: AttachItem[];
  createdAt: number;
  isSending?: boolean;
  isFailed?: boolean;
  isInbox: boolean;
  isUnread?: boolean;
  isHidden?: boolean;
  price?: number;
  decryptKey?: string;
  currency?: string;
  telegramStatus?: MessageTelegramStatus;
}

export interface TelegramPost {
  id: number;
  price: number;
  message: string;
  attachments?: AttachItem[];
  isPaid?: boolean;
}