import { h, createContext, FunctionalComponent as FC } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { Message } from '../types';
import { Post } from '../types/Post';

export interface MediaState {
  purchase: {
    purchases: Message[];
    loading: boolean;
    startFrom: number;
  };
  posts: {
    posts: Post[];
    loading: boolean;
    startFrom: number;
  };
  sponsors: {
    posts: Post[];
    loading: boolean;
    startFrom: number;
  }
}

const initMediaState = {
  purchase: {
    purchases: [],
    loading: true,
    startFrom: 0,
  },
  posts: {
    posts: [],
    loading: true,
    startFrom: 0,
  },
  sponsors: {
    posts: [],
    loading: true,
    startFrom: 0,
  }
}


export const MediaContext = createContext<{
  mediaState: MediaState;
  setMediaState: (state: MediaState) => void;
}>({
  mediaState: initMediaState,
  setMediaState: () => {},
});

export const MediaProvider: FC = ({ children }) => {
  const [state, setState] = useState<MediaState>(initMediaState);

  const setMediaState = useCallback((newState: MediaState) => {
    setState({
      ...state,
      ...newState,
    });
  }, [state])

  return (
    <MediaContext.Provider value={{ mediaState: state, setMediaState }} >
      {children}
    </MediaContext.Provider>
  )
}
