import { h, createContext, FunctionalComponent as FC } from 'preact';
import { useCallback, useState } from 'preact/hooks';
import { Card, Main, User } from '../types';

export const MainContext = createContext<{
  mainState: Main;
  setMainState: (state: Partial<Main>) => void;
}>({
  mainState: null!,
  setMainState: () => {},
});

export const MainProvider: FC = ({ children }) => {
  const [state, setState] = useState<Main>(null!);

  const setMainState = (newState: Partial<Main>) => {
    setState({
      ...state,
      ...newState,
    });
  };

  return (
    <MainContext.Provider value={{ mainState: state, setMainState }} >
      {children}
    </MainContext.Provider>
  )
}
