import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { PostProps } from './Post.interface';
import { Attachment } from '../Attachment/Attachment';
import { useMainState, useModel, useDate, useParseText } from '../../../hooks';
import { useMemo } from 'preact/hooks';
import { roundNumber } from '../../../utils';

export const Post: FC<PostProps> = ({ post }) => {
  const date = useDate(post.createdAt);
  const text = useParseText(post.text);

  const { mainState: { decryptKeys }} = useMainState();
  const model = useModel();
  const decryptKey = 'decryptKey' in post ? post.decryptKey : decryptKeys[model.id];

  const caption = useMemo(() => {
    const price = 'price' in post ? post.price : 0;
    return [date, price && `€${roundNumber(price)}`].filter(Boolean).join(', ');
  }, [date, post]);

  return (
    <div className={s.post}>
      <div className={s.postAttachments}>
        {post.attachments?.map((a, key) => (
          <Attachment attach={a} key={key} decryptKey={decryptKey} />
        ))}
      </div>
      <div className={s.postContent}>
        { text && <div className={s.postContentText}>{text}</div> }
        <div className={s.postContentCaption}>{caption}</div>
      </div>
    </div>
  );
}
