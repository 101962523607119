import style from "./style.css";

import { h } from "preact";
import { useEffect, useState, useCallback } from 'preact/hooks';
import { useRoute, useRouter } from 'react-router5';

import { ActionEnum, Message } from '../../types';
import { Api } from 'api';
import {inclineUserName, roundNumber, showAlert, statReachGoal} from '../../utils';
import { GENITIVE } from 'lvovich/lib/inclineRules';
import {Block, Button, Placeholder, PoweredBy, Screen} from 'ui';
import { useModel, useMainState, useTranslator } from '../../hooks';
import { routes } from '../../navigations/routes';
import { PremiumPlaceholder } from '../../shared/PremiumPlaceholder/PremiumPlaceholder';
import { TelegramPost } from '../../types/Message';

export const PayScreen = () => {
  const router = useRouter();
  const { route } = useRoute();
  const { objectId, action } = route.params;
  const { mainState: {
    eurBalance,
    subscription
  }} = useMainState();
  const [loading, setLoading] = useState( action === ActionEnum.BUY_MESSAGE);
  const [payLoader, setPayLoader] = useState( false);
  const [price, setPrice] = useState(0);
  const model = useModel();
  const t = useTranslator();

  const amount = [ActionEnum.BUY_MESSAGE, ActionEnum.BUY_TG_POST].indexOf(action) > -1 ? price : subscription.price;
  const canPay = amount <= eurBalance;

  useEffect(() => {
    setLoading(true);
    if (action === ActionEnum.BUY_MESSAGE) {
      Api.get<any, {
        message: Message
      }>(`/im/message/${objectId}`).then((data) => {
        setPrice(data.message.price || 0);
      }).finally(() => {
        setLoading(false);
      });
    } else if (action === ActionEnum.BUY_TG_POST) {
      Api.get<any, {
        post: TelegramPost
      }>(`/im/telegram/${objectId}/post`).then((data) => {
        if (data.post.isPaid) {
          showAlert(t('pay_telegram_post_paid'));
          Telegram.WebApp.close();
        } else {
          setPrice(data.post.price || 0);
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [action, objectId]);

  const handleByMessage = useCallback(() => {
    setPayLoader(true);
    Api.post(`/im/${objectId}/buy`).then(() => {
      Telegram.WebApp.close();
    }).catch((e) => {
      showAlert(e.message)
    }).finally(() => {
      setPayLoader(false);
    })
  } , [objectId]);

  const handleByPost = useCallback(() => {
    setPayLoader(true);
    statReachGoal('pay_click_button_buy');
    Api.post<null, {post: TelegramPost}>(`/im/telegram/${objectId}/post/buy`).then((r) => {
      statReachGoal('pay_success', {
        amount: r.post.price,
      });
      Telegram.WebApp.close();
    }).catch((e) => {
      statReachGoal('pay_error');
      showAlert(e.message)
    }).finally(() => {
      setPayLoader(false);
    })
  } , [objectId]);

  const handleTopUp = useCallback(() => {
    statReachGoal('pay_click_button_top_up');
    router.navigate(routes.BALANCE, {
      action,
      objectId,
      requireTopUpAmount: amount - eurBalance
    })
  } , [action, amount, eurBalance, objectId, router]);

  useEffect(() => {
    statReachGoal('view_pay');
  }, []);

  if (action === ActionEnum.BUY_TG_POST) {
    return (
      <Screen loading={loading}>
        <Block flex className={style.wrapper}>
          <Placeholder
            wrapped
            iconName="money"
            title={t('pay_post_title', { amount: roundNumber(amount) })}
            description={t('pay_post_description', { name: inclineUserName(model, GENITIVE).full})}
            action={
              <Button
                mainButton
                progress={payLoader}
                onClick={canPay ? handleByPost : handleTopUp}
              >
                {t('pay_message_button', { amount: roundNumber(amount) })}
              </Button>
            }
          />
        </Block>
        <PoweredBy footer />
      </Screen>
    )
  }

  if (action === ActionEnum.BUY_MESSAGE) {
    return (
      <Screen loading={loading}>
        <Block flex className={style.wrapper}>
          <Placeholder
            wrapped
            iconName="money"
            title={t('pay_message_title', { amount: roundNumber(amount) })}
            description={t('pay_message_description', { name: inclineUserName(model, GENITIVE).full})}
            action={
              <Button
                mainButton
                progress={payLoader}
                onClick={canPay ? handleByMessage : handleTopUp}
              >
                {t('pay_message_button', { amount: roundNumber(amount) })}
              </Button>
            }
          />
        </Block>
        <PoweredBy footer />
      </Screen>
    )
  }

  return (
    <Screen>
      <Block flex className={style.wrapper}>
        {subscription.isEnabled ? (
          <PremiumPlaceholder />
        ) : (
          <Placeholder
            wrapped
            iconName="regret"
            title={t('sponsor_not_active_title')}
            description={t('sponsor_not_active_description', { name: inclineUserName(model).full})}
          />
        )}
      </Block>
      <PoweredBy footer />
    </Screen>
  )
}
