import { useContext, useEffect, useRef } from 'preact/hooks';
import { DebugContext } from '../contexts/debugContext';

export const useDebug = () => {
  const { debug, setDebug } = useContext(DebugContext);
  const debugTapCounter = useRef(0);
  const lastTapTime = useRef(0);

  const tapDebugHandler = () => {
    if (Date.now() - lastTapTime.current > 300) {
      debugTapCounter.current = 0;
    } else {
      debugTapCounter.current++;
    }
    lastTapTime.current = Date.now();
    if (debugTapCounter.current >= 10) {
      setDebug(true);
      debugTapCounter.current = 0;
    }
  }

  return {
    debug,
    setDebug,
    tapDebugHandler,
  }
}
