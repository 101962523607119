
import { h, FunctionalComponent as FC, Fragment } from 'preact';
import {Block, Button, Input, PoweredBy, Screen, SegmentedControl, Title} from 'ui';
import { useCallback, useState } from 'preact/hooks';
import { roundNumber, showAlert } from '../../utils';
import { Api } from 'api';
import { ActionEnum, Tips as TipsType } from '../../types';
import { CurrencyEnum } from '../../types/Currency';
import { useMainState, useModel, useTranslator } from '../../hooks';
import { useRouter } from 'react-router5';
import { routes } from '../../navigations/routes';

export const TipsScreen: FC = () => {
  const router = useRouter();
  const amounts = [5, 10, 25, 50, 100];
  const model = useModel();
  const [amount, setAmount] = useState('5');
  const [message, setMessage] = useState('');
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [loading, setLoading] = useState(false);
  const { mainState: {
    eurBalance,
  }} = useMainState();
  const handleChangeAmount = useCallback((amount: string) => {
    setAmount(amount);
  }, [])
  const t = useTranslator();

  const floatAmount = parseFloat(amount);

  const handleSendTips = useCallback(async () => {
    if (!floatAmount || floatAmount < 1) {
      return showAlert(t('tips_amount_input_error_min', { min: 1 }))
    }
    if (floatAmount > 100) {
      return showAlert(t('tips_amount_input_error_max', { min: 100 }))
    }
    try {
      setLoading(true);
      const { tips } = await Api.post<null, {tips: TipsType}>(`/im/${model.id}/tips`, {
        message,
        currency: CurrencyEnum.EUR,
        amount: floatAmount,
      });
      await Api.post(`/im/tips/${tips.id}/send`, {
        currency: CurrencyEnum.EUR,
      });
      Telegram.WebApp.close();
    } catch (e: any) {
      if (e.code === 'insufficient_funds') {
        router.navigate(routes.BALANCE, {
          action: ActionEnum.TIPS,
          requireTopUpAmount: floatAmount - eurBalance,
        })
      } else {
        showAlert(e.message);
      }
    } finally {
      setLoading(false);
    }
  }, [floatAmount, t, model.id, message, router, eurBalance]);

  return (
    <Screen viewport>
      <Block>
        <Title
          title={t('tips_amount_title')}
          buttonText={!isCustomAmount ? t('tips_other_amount') : undefined}
          buttonOnClick={() =>  setIsCustomAmount(true)}
        />
        {isCustomAmount ? (
          <Input
            autoFocus
            prefix="€"
            type="number"
            placeholder="0"
            onChange={handleChangeAmount}
            value={amount}
            description={t('tips_amount_input_description', { min: 1, max: 100 })}
          />
        ) : (
          <Fragment>
            <SegmentedControl
              options={amounts.map((a) => ({
                label: `€${a}`,
                value: a.toString(),
              }))}
              defaultValue={amount}
              onChange={(value) => setAmount(value)}
            />
          </Fragment>
        )}
      </Block>
      <Block>
        <Title title={t('tips_message_title')} />
        <Input
          onChange={setMessage}
          placeholder={t('tips_message_placeholder')}
          value={message}
          multiline
        />
      </Block>
      <Block>
        <Button
          mainButton
          progress={loading}
          text={t('tips_button_send', { amount: floatAmount ? `€${roundNumber(floatAmount)}` : '' })}
          onClick={handleSendTips}
          size="44"
        />
      </Block>
      <PoweredBy footer />
    </Screen>
  );
}
