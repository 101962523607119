import style from "./style.css";

import { h, FunctionalComponent as FC } from 'preact';
import { memo } from 'preact/compat';
import cn from 'classnames';
import { useEffect } from 'preact/hooks';
import { getNodeText } from '../../../utils';
import { Loader } from '../Loader/Loader';

const Component:FC<ButtonProps> = ({
  children,
  size = '44',
  mode = 'primary',
  onClick,
  text,
  progress= false,
  mainButton = false,
  className,
}) => {
  const { WebApp } = Telegram;
  const handleClick = () => {
    Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    onClick();
  }
  const canMainButton = !!(mainButton && !window.localStorage.getItem('dev'));
  const buttonIsWhite = WebApp.themeParams.button_color === '#ffffff';

  useEffect(() => {
    const buttonColor = buttonIsWhite ?
      '#3478F6' :Telegram.WebApp.themeParams.button_color;
    const buttonTextColor = buttonIsWhite ?
      '#ffffff' :Telegram.WebApp.themeParams.button_text_color;
    if (canMainButton) {
      WebApp.MainButton[progress ? 'showProgress' : 'hideProgress']();
      WebApp.MainButton.onClick(onClick);
      WebApp.MainButton.color
      WebApp.MainButton.setParams({
        is_visible: true,
        text: text || getNodeText(children),
        color: buttonColor,
        text_color: buttonTextColor,
      })
    }
    return () => {
      if (canMainButton) {
        WebApp.MainButton.hideProgress();
        WebApp.MainButton.offClick(onClick);
        WebApp.MainButton.hide();
        buttonOnClicks.forEach((cb) => WebApp.MainButton.offClick(cb));
        buttonOnClicks = [];
      }
    }
  }, [children, canMainButton, onClick, progress, text]);

  if (canMainButton) {
    return null;
  }

  return (
    <div onClick={handleClick} class={cn(style.button, className, {
      [style.size44]: size === '44',
      [style.size40]: size === '40',
      [style.size32]: size === '32',

      [style.primary]: mode === 'primary',
      [style.secondary]: mode === 'secondary',
      [style.ghost]: mode === 'ghost',
      [style.destructive]: mode === 'destructive',
      [style.progress]: progress,
    })}>
      <span>{text || children}</span>
      {progress && (
        <div className={style.buttonLoader}>
          <Loader light={mode === 'primary' && !buttonIsWhite} />
        </div>
      )}
    </div>
  );
}

let buttonOnClicks: (() => void)[] = [];

export const Button = memo(Component, (prev, next) => {
  const { WebApp } = Telegram;
  const canMainButton = !!(next.mainButton && !window.localStorage.getItem('dev'));

  if (WebApp.initData && canMainButton) {
    if (prev.progress !== next.progress) {
      WebApp.MainButton[next.progress ? 'showProgress' : 'hideProgress']();
    }
    if (prev.onClick !== next.onClick) {
      buttonOnClicks.push(next.onClick);
      buttonOnClicks = buttonOnClicks.filter((fn) => fn !== prev.onClick);
      WebApp.MainButton.offClick(prev.onClick);
      WebApp.MainButton.onClick(next.onClick);
    }
    if (next.text && prev.text !== next.text) {
      WebApp.MainButton.setText(next.text);
    }
    return true;
  }
  return false;
});
