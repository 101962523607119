import { useEffect, useRef } from 'preact/hooks';

interface UseLoadMoreProps {
  handler: () => any | void;
  enabled: boolean;
  opts?: {
    isPreload?: boolean;
    isHorizontal?: boolean;
    node?: HTMLDivElement | null;
    isReversedDirection?: boolean;
  };
}

export function useLoadMore(props: UseLoadMoreProps) {
  const {
    handler,
    enabled,
    opts = {
      isPreload: false,
      isHorizontal: false,
      node: null,
      isReversedDirection: false,
    },
  } = props;

  const lastHandle = useRef(0);

  const handleScroll = () => {
    if (!enabled) {
      return;
    }

    const now = Date.now();
    if (now - lastHandle.current < 150) {
      return;
    }

    const node = opts.node;

    let scrollOffset, size, scrollSize;
    if (opts.isHorizontal) {
      scrollOffset = node ? node.scrollLeft : window.scrollX;
      size = node ? node.offsetWidth : window.innerWidth;
      scrollSize = node ? node.scrollWidth : document.body.scrollWidth;
    } else {
      scrollOffset = node ? node.scrollTop : window.scrollY;
      size = node ? node.offsetHeight : window.innerHeight;
      scrollSize = node ? node.scrollHeight : document.body.scrollHeight;
    }

    if (opts.isPreload) {
      if (scrollOffset < size) {
        lastHandle.current = now;
        handler();
      }
    } else if (opts.isReversedDirection) {
      if (size * 2 >= scrollOffset) {
        lastHandle.current = now;
        handler();
      }
    } else if (!opts.isReversedDirection) {
      if (scrollOffset + size * 2 >= scrollSize) {
        lastHandle.current = now;
        handler();
      }
    }
  };

  useEffect(() => {
    const node = opts.node;
    enabled && node && node.addEventListener('scroll', handleScroll);
    return () => {
      node && node.removeEventListener('scroll', handleScroll);
    };
  }, [enabled, opts.node, handler]);
}
