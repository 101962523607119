import style from "./style.css";

import { h, Fragment, FunctionalComponent as FC } from "preact";
import { useState, useMemo, useCallback } from "preact/hooks";
import { Api } from "api";
import { Block, Button, CardIcon, Input, Screen, SegmentedControl, Title } from 'ui';
import {roundNumber, showAlert, statReachGoal} from '../../utils';
import { useMainState, useModel, useTranslator } from '../../hooks';
import { useRoute, useRouter } from 'react-router5';
import { routes } from '../../navigations/routes';
import { wsEvent } from '../../api/realtime';
import {useEffect} from "react";

export const BalanceScreen: FC<any> = (props) =>
{
  const t = useTranslator();
  const router = useRouter();
  const { route } = useRoute();
  const needAmount = roundNumber(parseFloat(route.params.requireTopUpAmount || '0'));
  const { objectId, action } = route.params;
  const model = useModel();
  const { mainState: {
    eurBalance,
    card,
    eurTopUpFee,
  }, initMainState, setMainState } = useMainState();

  const minAmount = model.id === 170903 ? 1 : 5;

  const amounts = [5, 10, 25, 100, 500];
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const maxAmount = amounts[amounts.length - 1];
  const [[skip, loading], setLoading] = useState([false, false]);
  const amountList = useMemo(() => {
    if (!needAmount || needAmount >= amounts[maxAmount]) {
      return amounts;
    }
    const nearestValue =
      amounts.reduce(
        (prev, curr) =>
          Math.abs(curr - needAmount) < Math.abs(prev - needAmount) ? curr : prev,
        0,
      ) || amounts[0];
    return amounts.map((value) => Math.max((value === nearestValue ? needAmount : value), minAmount));
  }, [amounts, maxAmount, minAmount, needAmount]);

  const [amount, setAmount] = useState(
    Math.max(needAmount && needAmount < maxAmount ? needAmount : amounts[0], minAmount).toString(),
  );

  const handleTopUp = useCallback((skipAutoPay: boolean) => {
    const floatAmount = parseFloat(amount) || minAmount;

    statReachGoal(skipAutoPay ? 'balance_add_card' : 'balance_auto_top_up', {
      amount: floatAmount,
    });

    if (!floatAmount || floatAmount < minAmount) {
      return showAlert(t('balance_amount_input_error_min', { min: minAmount }));
    }
    if (floatAmount > 500) {
      return showAlert(t('balance_amount_input_error_max', { max: 500 }));
    }
    setLoading([skipAutoPay, true]);
    Api.post("/pay/top_up", {
      amount: floatAmount,
      skipAutoPay,
      modelId: model.id,
      target: action && (action === "subscription" ? "sponsor_subscription" : action === 'tips' ? action : "chat"),
      targetObject: action === 'tips' ? Math.floor(floatAmount) : parseInt(objectId, 10) || 0,
    }).then((data: any) => {
      if (data.token) {
        router.navigate(routes.PAY_FORM, {
          token: data.token
        })
      } else if (data.status == "sent_payment") {
        if (objectId && action) {
          wsEvent.once("eur_balance_top_up", () => {
            router.navigate(routes.PAY, {
              action,
              objectId,
            })
            setLoading([skipAutoPay, false]);
          })
        } else {
          Telegram.WebApp.close();
        }
      }
    }).catch(() => {
      setLoading([skipAutoPay, false]);
    })
  }, [action, amount, objectId, router, t, minAmount]);

  const handleChangeAmount = useCallback((amount: string) => {
    setAmount(amount);
    statReachGoal('balance_choose_amount', { amount });
  }, [])

  useEffect(() => {
    statReachGoal('view_balance');
  }, []);

  const [currencyFee, amountWithFee] = useMemo(() => {
    const floatAmount = parseFloat(amount) || minAmount;
    const currencyFee = (floatAmount * eurTopUpFee);
    return [currencyFee, floatAmount + currencyFee].map((n) => roundNumber(n));
  }, [amount, eurTopUpFee, minAmount]);

    return (
      <Screen>
        <Fragment>
          <Block>
            <div className={style.balance}>
              <div className={style.balance__title}>{t('balance_available_balance')}</div>
              <div className={style.balance__amount}>€{roundNumber(eurBalance)}</div>
              { !!needAmount && <div className={style.balance__needAmount}>
                {t('balance_amount_need_amount', { amount: needAmount })}
              </div>}
            </div>
          </Block>
          <Block>
            <Title
              title={t('balance_amount_title')}
              buttonText={!isCustomAmount ? t('balance_amount_other_amount') : undefined}
              buttonOnClick={() =>  setIsCustomAmount(true)}
            />
            {isCustomAmount ? (
              <Input
                autoFocus
                prefix="€"
                type="number"
                onChange={handleChangeAmount}
                value={amount}
                description={(<>
                  {t('balance_amount_other_disclaimer', { min: 5, max: 500 })}
                  <br />
                  {t('balance_amount_input_description', { min: 5, max: 500 })}
                </>)}
              />
            ) : (
              <>
                <SegmentedControl
                  options={amountList.map((a) => ({
                    label: `€${a}`,
                    value: a.toString(),
                  }))}
                  defaultValue={amount}
                  onChange={handleChangeAmount}
                />
                <div className={style.amountDescription}>
                  {t('balance_amount_other_disclaimer')}
                </div>
              </>
            )}
            <div className={style.amountFee}>
              {t('balance_amount_fee_description', { fee: currencyFee }) + ' '}
              <strong>€{amountWithFee}</strong>
            </div>
          </Block>
          <Block>
            {card ? (
              <Button progress={!skip && loading} onClick={() => handleTopUp(false)} size="44">
                {t('balance_button_top_up_via_card', { amount: amountWithFee, card: card.last4 })}
                <CardIcon type={card?.brand} className={style.payButton__icon} />
              </Button>
            ) : (
              <Button
                progress={!skip && loading}
                mainButton
                onClick={() => handleTopUp(false)}
                text={t('balance_button_add_new_card')}
              />
            )}
            {card && <Button
                mode="secondary"
                progress={skip && loading}
                onClick={() => handleTopUp(true)}
            >{t('balance_button_add_new_card')}</Button>}
          </Block>
        </Fragment>
      </Screen>
    )
  };

