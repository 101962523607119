import s from "./style.css"

import { h, FunctionalComponent as FC } from 'preact';
import { useRef, useEffect, useState, useCallback, useMemo } from 'preact/hooks';
import cn from 'classnames';
import { useRoute } from 'react-router5';

import { Button, Loader } from 'ui';
import { useTranslator } from '../../hooks';
import {statReachGoal} from "../../utils";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from './img.png';

export const PayFormScreen: FC = (props) => {
  const frame = useRef<any>();
  const t = useTranslator();
  const { route } = useRoute();
  const { token } = route.params;
  const readyInterval = useRef<ReturnType<typeof setTimeout>>();
  const submitInterval = useRef<ReturnType<typeof setTimeout>>();
  const buttonDomNodeRef = useRef<HTMLDivElement>();
  const [displayButton, setDisplayButton] = useState(true);
  const [isFormReady, setFormReady] = useState(false);

  useEffect(() => {
    statReachGoal('view_pay_form');
  }, []);

  const translations = useMemo(
      () => ({
        texts: {
          failedPaymentScreen: {
            title: t('pay_form_unknown_error'),
          },
          paymentForm: {
            buttonPayLabel: t('pay_form_pay'),
            cardholderLabel: t('pay_form_card_holder'),
            cardholderPlaceholder: t('pay_form_card_holder_placeholder'),
            cardNumberLabel: t('pay_form_card_number'),
            expireDateLabel: t('pay_form_expiry_date'),
            expireDateNote: t('pay_form_date_note'),
            expireDatePlaceholder: t('pay_form_expiry_date_placeholder'),
            recurrentLabel: t('pay_form_accept_recurring_payments'),
            termsAgreement: `${t('pay_form_terms_agreement')} {{#link}}${t(
                'pay_form_terms_agreement_link',
            )}{{/link}}`,
            validationErrors: {
              INVALID_CARD_NUMBER: t('pay_form_invalid_card_number'),
              INVALID_CVV: t('pay_form_invalid_code'),
              INVALID_EXPIRY_DATE: t('pay_form_invalid_date'),
              IS_REQUIRED: t('pay_form_required_field'),
            },
          },
          paymentProcessScreen: {
            description: t('pay_form_payment_in_progress_desc'),
            title: t('pay_form_payment_in_progress'),
          },
          redirectionScreen: {
            followTheLink: `${t('pay_form_redirect_to_bank_desc')} {{#link}}${t(
                'pay_form_redirect_to_bank_link',
            )}{{/link}}.`,
            title: t('pay_form_payment_in_progress'),
            waitForRedirectToBanksPage: `${t(
                'pay_form_redirect_to_bank_after',
            )} {{#strong}}{{count}} ${t('pay_form_redirect_to_bank_count')}{{/strong}} ${t(
                'pay_form_redirect_to_bank_will_redirect',
            )}`,
          },
          successPaymentScreen: {
            title: t('pay_form_success_title'),
          },
          transactionData: {
            amountLabel: t('pay_form_transaction_data_amount'),
            creditCardLabel: t('pay_form_transaction_data_credit_card'),
            transactionIdLabel: t('pay_form_transaction_data_transaction_id'),
          },
          unknownError: t('pay_form_unknown_error_desc'),
        },
      }),
      [t],
  );

  useEffect(() => {
    if (token) {
      frame.current.contentWindow.addEventListener('DOMContentLoaded', () => {
        const PaymentForm = frame?.current.contentWindow.SmglPaymentForm as any;
        const paymentForm = new PaymentForm(token, translations);

        paymentForm.onReady = () => {
          readyInterval.current = setInterval(() => {
            const document = frame?.current.contentWindow.document;
            if (!document) { return }
            // [
            //   '--theme-text-color',
            //   '--theme-hint-color',
            //   '--theme-bg-color',
            //   '--theme-link-color'
            // ].forEach((property) => {
            //   const value = getComputedStyle(window.document.body)
            //       .getPropertyValue(property);
            //   document.body.style.setProperty(property, value);
            // })

            buttonDomNodeRef.current = document.querySelector('button[type="submit"]');
            const checkboxContainer = document.querySelector(".smgl-Checkbox__container") as HTMLDivElement;
            const checkbox = document.querySelector(".smgl-Checkbox__input") as HTMLInputElement;

            if (checkboxContainer) {
              checkbox.click();
              checkboxContainer.style.opacity = '0';
              setFormReady(true);
              clearInterval(readyInterval.current);
            }

            const securityBox = document.querySelector(".smgl-security_block") as HTMLDivElement;
            if (!securityBox && checkboxContainer && checkboxContainer.parentNode) {
              const node = document.createElement('div');
              node.innerHTML = `<div class="smgl-security_block">
<div style="color: green; font-size: 14px;line-height: 18px;">${t('pay_form_security')}</div>
<img src="${img}" style="height: 60px;" />
</div>`;
              checkboxContainer.parentNode.insertBefore(node, checkboxContainer.parentNode.children[0]);
            }
          }, 100);
        };
        paymentForm.render();
      });
    }
    return () => {
      clearInterval(readyInterval.current);
    }
  }, [token, frame]);

  useEffect(() => {
    if (isFormReady) {
      submitInterval.current = setInterval(() => {
        const document = frame?.current.contentWindow.document;
        if (!document) return;
        if (!document.querySelector('form')) {
          setDisplayButton(false);
          clearInterval(submitInterval.current);
        }
      }, 500);
    }
    return () => {
      clearInterval(submitInterval.current);
    }
  }, [isFormReady])

  const handlePay = useCallback(() => {
    buttonDomNodeRef.current?.click();
    statReachGoal('send_pay_form');
  }, [buttonDomNodeRef]);

  return (
      <div className={s.wrapper}>
        <iframe
            className={cn(s.iframe, {
              [s.iframeDisplay]: isFormReady
            })}
            ref={frame}
            src={`/pay_form.html`}
            frameBorder={0}
        />
        { displayButton && <Button className={s.footerButton} mainButton onClick={handlePay}>{t('pay_form_pay')}</Button>}
        { !isFormReady && <Loader className={s.loader} wrapped /> }
      </div>
  );
}
