import s from './style.css';

import { h, FunctionComponent as FC } from 'preact';
import { useState, useCallback } from 'preact/hooks';
import cn from 'classnames';

import { SegmentedControlProps } from './SegmentedControl.interface';

function getSelectedIndex(defaultValue: any, options: SegmentedControlProps['options']) {
  if (!defaultValue) {
    return 0;
  }

  for (let i = 0; i < options.length; i++) {
    if (defaultValue === options[i].value) {
      return i;
    }
  }

  return 0;
}

export const SegmentedControl: FC<SegmentedControlProps> = (props) => {
  const { options, mode = 'default', defaultValue, onChange = null } = props;

  const [selected, setSelected] = useState<any>(getSelectedIndex(defaultValue, options));

  const handleClick = useCallback(
    (value: number) => () => {
      setSelected(value);
      Telegram.WebApp.HapticFeedback.impactOccurred('soft');
      onChange && onChange(options[value].value);
    },
    [setSelected, options, onChange],
  );

  return (
    <div className={cn(s.wrapper, {
      [s.dark]: Telegram.WebApp.colorScheme === 'dark',
    })}>
      <div className={s.in}>
        {options.map(({ label, value, counter = null, badge = false }, i) => {
          return (
            <div
              key={value}
              className={cn(s.item, {
                [s.selected]: selected === i,
              })}
              onClick={handleClick(i)}
            >
              {label}
            </div>
          );
        })}
        <div
          className={s.indicator}
          style={{
            width: `${100 / options.length}%`,
            transform: `translateX(${100 * selected}%)`,
          }}
        />
      </div>
    </div>
  );
};
