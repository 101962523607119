import { useContext } from 'preact/hooks';
import { TranslatorContext } from '../translation';

export const useWikiLink = (path?: string) => {
    const lang = useContext(TranslatorContext).lang;
    return 'https://wiki.ton.place' + (lang === 'ru' ? '' : '/v/eng') + (path || '');
};

export const useWikiLinkCallback = (path?: string) => {
    const link = useWikiLink(path);
    return () => window.open(link, '_blank');
};
