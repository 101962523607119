import style from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { Children } from 'preact/compat';
import { useRef } from 'preact/hooks';
import cn from 'classnames';

import { useLoadMore } from '../../../hooks/useLoadMore';
import { ScrollViewProps } from './ScrollView.interface';
import { Loader } from '../Loader/Loader';

export const ScrollView: FC<ScrollViewProps> = ({
  canLoadMore = false,
  onLoadMore,
  children,
  isLoadingMore,
  isReversedDirection,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLoadMore({
    handler: () => onLoadMore && onLoadMore(),
    enabled: canLoadMore && !!onLoadMore,
    opts: {
      node: ref.current,
      isReversedDirection,
    },
  })

  return (
    <div ref={ref} className={style.scrollView}>
      {children}
      {isLoadingMore && !isReversedDirection && (
        <Loader wrapped className={cn({
          [style.scrollViewLoadMore]: !!Children.toArray(children).length,
        })} />
      )}
    </div>
  )
}
