import { useMemo } from 'preact/compat';

export const useParseText = (text: string) => {
  return useMemo(() => {
    if (text.substring(0, 1) === '{') {
      try {
        const ops = JSON.parse(text).ops;
        let rows: string[] = [];
        for (let i = 0; i < ops.length; i++) {
          rows.push(ops[i].insert);
        }
        return rows.join(' ').replaceAll('\n', ' ').trim();
      } catch (error) {
        return '';
      }
    } else {
      return text;
    }
  }, [text]);
};
