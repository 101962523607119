import s from './message.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Message } from '../../types';
import { Api } from 'api';
import { useRoute } from 'react-router5';
import {Post, PoweredBy, Screen} from 'ui';

export const MessageScreen: FC = () => {
  const { route } = useRoute();
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState(true);
  const { objectId, action } = route.params;

  useEffect(() => {
    setLoading(true);
    Api.get<any, {
      message: Message
    }>(`/im/message/${objectId}`).then((data) => {
      setMessage(data.message);
    }).finally(() => {
      setLoading(false);
    }).then(() => {
      Telegram.WebApp.expand();
    });
  }, [action, objectId]);

  return (
    <Screen loading={loading}>
      { message ? (
        <Post post={message} />
      ) : (
        <div>Error</div>
      )}
    </Screen>
  )
}
