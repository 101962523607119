import style from './style.css'

import {FunctionalComponent as FC, h} from "preact";
import {Block} from "ui";
import {useTranslator} from "../../../hooks";
import {PoweredByProps} from "./PoweredBy.interface";
import cn from "classnames";

export const PoweredBy: FC<PoweredByProps> = ({ footer }) => {
  const t = useTranslator();

  return (
    <Block className={cn(style.poweredBy, {
      [style.poweredByFooter]: footer,
    })}>
      <a href="https://ton.place" target="_blank" rel="noreferrer">
        <span>{t('menu_powered_by')}</span>
        Ton.Place
      </a>
    </Block>
  );
}