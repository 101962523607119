import { h, Fragment } from 'preact';
import crypto from 'crypto';
import { incline } from 'lvovich';
import { format as dateFnsFormat } from 'date-fns';
import { enUS as en, ru } from 'date-fns/locale';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { DeclentionStrT, NOMINATIVE } from 'lvovich/lib/inclineRules';
import amplitude from 'amplitude-js';

import { User } from '../types';
import { getLanguage } from '../translation';

export const setModelId = (id: number) => {
  try {
    window.localStorage.setItem('modelId', id.toString());
  } catch (e) {
    console.error(e);
  }
}

export const getModelId = (): number => {
  const modelId = window.localStorage.getItem('modelId');
  return modelId ? parseInt(modelId) : 0;
}

export const showAlert = (message: string): Promise<undefined> => new Promise((resolve) => {
  if (parseFloat(Telegram.WebApp.version) >= 6.2 && !window.localStorage.getItem('dev')) {
    Telegram.WebApp.showAlert(message,() => resolve(undefined));
  } else {
    window.alert(message)
    resolve(undefined);
  }
});

export const showConfirm = (message: string): Promise<boolean> => new Promise((resolve) => {
  if (parseFloat(Telegram.WebApp.version) >= 6.2 && !window.localStorage.getItem('dev')) {
    Telegram.WebApp.showConfirm(message, value => resolve(value));
  } else {
    resolve(window.confirm(message));
  }
});

export function formatInputAmount(raw: string, fractionDigits = 4) {
  // replace dots to commas
  raw = raw.replace(/\,/g, '.');
  // remove non-numeric charsets
  raw = raw.replace(/[^0-9\.]/g, '');
  // allow only one leading zero
  raw = raw.replace(/^([0]+)/, '0');
  // prepend zero before leading comma
  raw = raw.replace(/^([\.]+)/, '0.');

  if (raw.substr(0, 1) === '0' && raw.substr(1, 1) !== '.' && raw.length > 1) {
    raw = raw.substr(1);
  }

  // allow only one comma
  let commaFound = false;
  raw = raw.replace(/\./g, () => {
    if (!commaFound) {
      commaFound = true;
      return '.';
    } else {
      return '';
    }
  });

  // apply length limitations
  const exp = raw.split('.');
  exp[0] = exp[0].substr(0, 8);
  if (exp[1]) {
    exp[1] = exp[1].substr(0, fractionDigits);
  }

  return exp.join('.');
}



// @ts-ignore
const cyrillicToTranslit = new CyrillicToTranslit();

export const transliterate = (value: string) => {
  return cyrillicToTranslit.transform(value, ' ');
};

export const roundNumber = (input: number, decimals = 2) => {
  const roundTo = Math.pow(10, decimals);
  return Math.round(input * roundTo) / roundTo;
}

export const inclineUserName = (
  user: User,
  d: DeclentionStrT = NOMINATIVE,
  lang = getLanguage(),
) => {
  if (lang === 'ru') {
    const u = incline(
      {
        gender: user.sex ? 'female' : 'male',
        first: user.firstName ?? '',
        last: user.lastName ?? '',
      },
      d,
    );

    return {
      first: u.first,
      last: u.last,
      full: [u.first, u.last].join(' '),
    };
  }
  return {
    first: transliterate(user.firstName),
    last: transliterate(user.firstName),
    full: [user.firstName, user.lastName].map(transliterate).join(' '),
  };
};

export const getToken = () => {
  const modelId = getModelId();
  const token = window.localStorage.getItem('token');
  if (token) {
    return token;
  }

  const data = Telegram.WebApp.initDataUnsafe as any;
  const tgData = Object.keys(data)
    .filter(key => key !== "hash")
    .sort()
    .map(k => `${k}=${ typeof data[k] === "object" ? JSON.stringify(data[k]) : data[k]}`)
    .join("\n");

  return btoa([
    modelId,
    unescape(encodeURIComponent(tgData)),
    data.hash
  ].join(";"));
}


export const getNodeText = (node?: any): string => {
  if (typeof node === 'string') return node;
  if (typeof node === 'number') return node.toString();
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node) {
    return getNodeText(node.props.children)
  }
  return '';
}

export const format = (date: number | Date, formatString: string, options?: any) =>
  dateFnsFormat(date, formatString, {
    // locale: 'en', // TODO get locale
    ...options,
  });


const ALGORITHM = 'aes-256-cbc';
const BLOCK_SIZE = 16;

export function aesDecrypt(cipherText: string, key: string) {
  if (!key) return cipherText;
  try {
    const contents = Buffer.from(cipherText, 'hex');
    const iv = contents.slice(0, BLOCK_SIZE);
    const textBytes = contents.slice(BLOCK_SIZE);

    const decipher = crypto.createDecipheriv(ALGORITHM, key, iv);
    // @ts-ignore
    let decrypted = decipher.update(textBytes, 'hex', 'utf8');
    // @ts-ignore
    decrypted += decipher.final('utf8');
    return decrypted;
  } catch (e) {
    return null;
  }
}

export const dateFnsLocale: Record<string, any> = {
  ru,
  en,
};

export function rnbr(text: string) {
  if (!text) {
    return '';
  }

  return text.split(`\n`).map((item, i) => {
    return (
      <Fragment key={i}>
        {i > 0 && <br />}
        {item}
      </Fragment>
    );
  });
}

export const statReachGoal = (action: string, params?: object) => {
  console.log('statReachGoal', action, params);
  return amplitudeEvent(action, params);
};

export const amplitudeEvent = (action: string, params?: object) => {
  try {
    return amplitude.getInstance().logEvent(action, {
      modelId: getModelId(),
      ...(params || {}),
    });
  } catch (e) {
    console.error('amplitudeEvent error', e);
  }
};

