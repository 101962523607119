import './style/index.css';
import App from './components/app';
import amplitude from 'amplitude-js';
import {statReachGoal} from "./utils";

Telegram.WebApp.ready();


amplitude.getInstance().init('f634775b4b835d00f20cab9aeac30b3c', undefined, {
  platform: 'Bot',
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
});

statReachGoal('init');

// @ts-ignore
window.initUrl = window.location.href;

export default App;
