import { h } from 'preact';
import { useRoute  } from 'react-router5';
import { useEffect } from 'preact/hooks';

import { BalanceScreen } from '../routes/balance/balance';
import { MenuScreen } from '../routes/menu/menu';
import { PayScreen } from '../routes/pay/pay';
import { DebugScreen } from '../routes/debug/debug';
import { TipsScreen } from '../routes/tips/tips';
import { useMainState, useDebug, useRealTime } from '../hooks';
import { Screen } from 'ui';
import { SponsorScreen } from '../routes/sponsor/sponsor';
import { routes as r } from '../navigations/routes';
import { PayFormScreen } from '../routes/payForm/payForm';
import { MessageScreen } from '../routes/message/message';
import { MediaScreen } from '../routes/media/media';
import router from '../navigations/router';
import { ActionEnum } from '../types';
import { TopUpFailedScreen } from '../routes/topUpFailed/topUpFailedScreen';
import { initWebSocket } from '../api/realtime';

export default () => {
  const { debug } = useDebug();
  const { route } = useRoute()
  const { mainState, initMainState } = useMainState();

  const hasSubscription = !!mainState?.hasSubscription;

  useRealTime();

  useEffect(() => {
    initMainState();
  }, []);

  if (debug) {
    return <DebugScreen />
  } else if (mainState === null) {
    return <Screen loading />
  } else if (route.name === r.MENU) {
    return <MenuScreen />
  } else if (route.name === r.PAY) {
    if (hasSubscription && route.params.action === ActionEnum.SUBSCRIPTION) {
      router.navigate(r.SPONSOR, {}, {
        replace: true,
        transition: false
      })
      return null;
    }
    return <PayScreen />
  } else if (route.name === r.PAY_FORM) {
    return <PayFormScreen />
  } else if (route.name === r.BALANCE) {
    return <BalanceScreen />
  } else if (route.name === r.TIPS) {
    return <TipsScreen />
  } else if (route.name === r.DEBUG) {
    return <DebugScreen />
  } else if (route.name === r.SPONSOR) {
    if (!hasSubscription) {
      router.navigate(r.PAY, {
        action: 'subscription',
      }, {
        replace: true,
        transition: false
      })
      return null;
    }
    return <SponsorScreen />
  } else if (route.name === r.MEDIA) {
    return <MediaScreen />
  } else if (route.name === r.MESSAGE) {
    return <MessageScreen />
  } else if (route.name === r.TOP_UP_FAILED) {
    return <TopUpFailedScreen />
  } else if (route.name === r.POSTS) {
    router.navigate(r.MEDIA, {
      section: 'premium',
    }, {
      replace: true,
      transition: false
    })
    return null;
  }
};

