import style from './style.css';

import { h } from "preact";
import { useMemo } from "preact/hooks";
import {Block, List, ListItem, PoweredBy, Screen, Title} from 'ui';

import IconCrown from '../../assets/colorIcons/crown.svg';
import IconMoney from '../../assets/colorIcons/money.svg';
import IconMedia from '../../assets/colorIcons/media.svg';

import {inclineUserName, roundNumber, statReachGoal} from '../../utils';
import { DATIVE, GENITIVE } from 'lvovich/lib/inclineRules';
import { useModel, useMainState, useTranslator } from '../../hooks';
import { routes as r } from '../../navigations/routes';
import router from '../../navigations/router';
import {useEffect} from "preact/compat";

export const MenuScreen = () => {
  const {mainState: {
    eurBalance,
    subscription: s,
    hasSubscription,
  }} = useMainState();
  const model = useModel();
  const t = useTranslator();

  useEffect(() => {
    statReachGoal('view_menu');
  }, []);

  const menuItemPremiumCaption =  useMemo(() => {
    let key: string;
    if (s.videosCnt > 0 && s.photosCnt > 0) {
      key = 'menu_item_premium_caption_active';
    } else if (s.videosCnt > 0) {
      key = 'menu_item_premium_caption_active_only_videos'
    } else if (s.photosCnt > 0) {
      key = 'menu_item_premium_caption_active_only_photos'
    } else {
      key = 'menu_item_premium_caption_active_empty'
    }

    if (!hasSubscription) {
      key = key.replace('_active', '');
    }

    let string = t(key, {
      photo: s.photosCnt,
      video: s.videosCnt,
      name: inclineUserName(model, GENITIVE).first,
    });
    if (s.videosCnt === 1) {
      string = string.replace('videos', 'video');
    }
    if (s.photosCnt === 1) {
      string = string.replace('photos', 'photo');
    }
    return string;
  }, [hasSubscription, model, s.photosCnt, s.videosCnt, t]);

  return (
    <Screen>
      <Block>
        <Title
          title={t('menu_subtitle_actions')}
          buttonText={
            <span>
              {t('menu_subtitle_balance')}
              {" "}
              <span className={style.balanceAmount}>€{roundNumber(eurBalance)}</span>
            </span>
          }
          buttonOnClick={() => { router.navigate(r.BALANCE) }}
        />
        <List mode="extended">
          <ListItem
            icon={<IconMoney />}
            title={t('menu_item_tips_title')}
            route={r.TIPS}
            description={
              t('menu_item_tips_caption', {
                name: inclineUserName(model, DATIVE).first,
              })
            }
          />
          <ListItem
            icon={<IconMedia />}
            route={r.MEDIA}
            title={t('menu_item_media_title')}
            description={t('menu_item_media_caption')}
          />
        </List>
      </Block>
      <Block>
        <Title title={t('menu_subtitle_subscription')} />
        <List mode="extended">
          <ListItem
            icon={<IconCrown />}
            route={r.SPONSOR}
            title={t('menu_item_premium_title')}
            description={menuItemPremiumCaption}
          />
        </List>
      </Block>
      <PoweredBy />
    </Screen>
  );
};
