import { h } from 'preact';
import { RouterProvider } from 'react-router5';

import router from '../navigations/router';
import Router from './router';

import { ModelIdProvider } from '../contexts/modelIdContext';
import { DebugProvider } from '../contexts/debugContext';
import { MainProvider } from '../contexts/mainContext';
import { MediaProvider } from '../contexts/mediaContext';
import { TranslatorProvider } from '../translation';

Telegram.WebApp.setHeaderColor('secondary_bg_color');
Telegram.WebApp.setBackgroundColor('secondary_bg_color');

const App = () => (
  <DebugProvider>
    <ModelIdProvider>
      <MainProvider>
        <MediaProvider>
          <RouterProvider router={router}>
            <TranslatorProvider>
              {/* @ts-ignore */}
              <Router />
            </TranslatorProvider>
          </RouterProvider>
        </MediaProvider>
      </MainProvider>
    </ModelIdProvider>
  </DebugProvider>
);

router.start();

export default App;
