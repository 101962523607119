import s from '../../style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useEffect } from 'preact/hooks';
import { Placeholder, Post, ScrollView } from 'ui';
import { inclineUserName } from '../../../../utils';
import { useMainState, useModel, useSponsors, useTranslator } from '../../../../hooks';
import { PremiumPlaceholder } from '../../../../shared/PremiumPlaceholder/PremiumPlaceholder';
import { GENITIVE } from 'lvovich/lib/inclineRules';

export const Sponsors: FC = () => {
  const t = useTranslator();
  const { posts, loadPosts, loading } = useSponsors();
  const model = useModel();
  const { mainState: {
    hasSubscription,
  } } = useMainState();

  useEffect(() => {
    if (posts.length === 0 && hasSubscription) {
      loadPosts(true);
    }
  }, [hasSubscription, posts.length]);

  if (!hasSubscription) {
    return (
      <PremiumPlaceholder
        className={s.mediaPlaceholder}
      />
    )
  }

  if (!loading && !posts.length) {
    return (
      <Placeholder
        wrapped
        className={s.mediaPlaceholder}
        iconName="monocle"
        title={t('media_premium_empty_title')}
        description={t('media_premium_empty_description', { name: inclineUserName(model, GENITIVE).first })}
      />
    )
  }

  return (
    <ScrollView isLoadingMore={loading} canLoadMore={!loading} onLoadMore={() => loadPosts()}>
      {posts.map((p) => (
        <Post key={p.id} post={p} />
      ))}
    </ScrollView>
  )
}
