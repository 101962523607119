import { API_HOST } from './index';
import { getToken } from '../utils';
import EventEmitter from 'events';

type Event = {
  type: string;
  body: any
}

export const wsEvent = new EventEmitter();

export const initWebSocket = () => {
  let socket: WebSocket | null = null;
  let reconnectTimer: any = 0;
  let forceClosed = false;
  const token = getToken();
  let currentConnId = 0;
  let lastEventId = 0;

  const connectionDidClose = () => {
    if (forceClosed) {
      return;
    }

    clearTimeout(reconnectTimer);
    reconnectTimer = setTimeout(connect, 3000);
  }

  const connect = () => {
    const connId = ++currentConnId;

    if (socket) {
      try {
        socket.close();
        socket = null;
      } catch (e) {}
    }

    socket = new WebSocket(
      `wss://${API_HOST}/ws?access_token=TgAuth ${token}&last_event_id=${lastEventId}`,
    );
    socket.onopen = () => {
      //console.log('ws connected');
    };
    socket.onmessage = (e) => {
      const messages = e.data.split('\n');
      for (let i = 0; i < messages.length; i++) {
        let event;
        try {
          event = JSON.parse(messages[i]);
        } catch (err: any) {
          console.error('error json parse:', err.message);
          continue;
        }

        lastEventId = event.id;
        wsEvent.emit(event.type, event.body);
      }
    }
    socket.onclose = () => {
      if (connId === currentConnId) {
        connectionDidClose();
      }
    }
    socket.onerror = (err) => {
      console.error('ws:', err);
      if (connId === currentConnId) {
        connectionDidClose();
      }
    };
  }

  connect();

  return () => {
    clearTimeout(reconnectTimer);
    forceClosed = true;
    socket?.close();
    socket = null;
  };
}

