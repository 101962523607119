import createRouter from 'router5'
import browserPlugin from 'router5-plugin-browser'
import listenersPlugin from 'router5-plugin-listeners';
import { routes as r } from './routes';

const routes = [
  { name: r.MENU, path: '/' },
  { name: r.PAY_FORM, path: '/payForm' },
  { name: r.PAY, path: '/pay' },
  { name: r.BALANCE, path: '/balance' },
  { name: r.TIPS, path: '/tips' },
  { name: r.DEBUG, path: '/debug' },
  { name: r.MEDIA, path: '/media' },
  { name: r.SPONSOR, path: '/sponsor' },
  { name: r.MESSAGE, path: '/message' },
  { name: r.POSTS, path: '/posts' },
  { name: r.TOP_UP_FAILED, path: '/topup/failed' },
]

const router = createRouter(routes, {
  allowNotFound: false,
  autoCleanUp: false,
  defaultRoute: r.MENU,
  defaultParams: {},
  queryParamsMode: 'loose',
  trailingSlashMode: 'default',
  strictTrailingSlash: false,
  caseSensitive: false,
  urlParamsEncoding: 'default',
});

router.usePlugin(browserPlugin())
router.usePlugin(listenersPlugin());

router.useMiddleware((router) => (toState, fromState, done) => {
  const transition = window.history?.state?.meta?.options?.transition;
  if (!fromState && transition && toState.meta) {
    toState.meta.options = {
      transition,
    };
  }
  if (fromState && toState) {
    if (toState.meta?.options) {
      const toTransition = toState.meta?.options?.transition;
      const replace = toState.meta?.options?.replace;

      if (replace) {
        if (toTransition === false) {
          // @ts-ignore
          toState.meta.options = fromState.meta?.options;
        } else {
          toState.meta.options.transition = fromState.meta?.options?.transition;
        }
      } else {
        toState.meta.options.transition = (toTransition || toTransition === undefined);
      }
    }
  }
  done();
});

export const hasHistoryBack = (): boolean => {
  const { meta } = history.state;
  return !!meta?.options?.transition;
};


export const goBack = () => {
  window.history.back();
}

// @ts-ignore
router.addListener((from, to) => {
  const { WebApp } = Telegram;
  if (hasHistoryBack()) {
    WebApp.BackButton.onClick(goBack);
    WebApp.BackButton.show();
  } else {
    WebApp.BackButton.offClick(goBack);
    WebApp.BackButton.hide()
  }

  console.log('hasHistoryBack', hasHistoryBack());

  WebApp.setHeaderColor('secondary_bg_color');
  WebApp.setBackgroundColor('secondary_bg_color');
});

export default router;
