export enum routes {
  MENU = 'MENU',
  PAY_FORM = 'PAY_FORM',
  PAY = 'PAY',
  BALANCE = 'BALANCE',
  TIPS = 'TIPS',
  DEBUG = 'DEBUG',
  SPONSOR = 'SPONSOR',
  MEDIA = 'MEDIA',
  MESSAGE = 'MESSAGE',
  POSTS = 'POSTS',
  TOP_UP_FAILED = 'TOP_UP_FAILED',
}
