import { useContext } from 'preact/hooks';
import { ModelIdContext } from '../contexts/modelIdContext';
import { useMainState } from './useMainState';

export const useModelId = () => {
  const modelId = useContext(ModelIdContext);
  return modelId;
}

export const useModel = () => {
  const { mainState } = useMainState()
  return mainState.subscription.user;
}
