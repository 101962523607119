import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import cn from 'classnames';

import { PlaceholderProps } from './Placeholder.interface';
import { LottieIcon } from 'ui';
import { useDebug } from '../../../hooks/useDebug';


export const Placeholder: FC<PlaceholderProps> = ({
  iconName,
  title,
  description,
  className,
  wrapped = false,
  action,
}) => {
  const renderContent = () => (
    <div className={cn(s.placeholder, className)}>
      {iconName && <LottieIcon size={120} name={iconName} className={s.placeholderIcon} />}
      <div>
        {title && <div className={s.placeholderTitle} children={title} />}
        {description && <div className={s.placeholderDescription} children={description} />}
      </div>
      {action && (
        <div className={s.placeholderAction}>{action}</div>
      )}
    </div>
  );

  return wrapped ? (
    <div className={s.placeholderWrapper}>
      {renderContent()}
    </div>
  ) : renderContent();


}
