import { h, createContext, FunctionalComponent as FC } from 'preact';
import { getModelId, setModelId } from '../utils';
export const ModelIdContext = createContext(0);

export const ModelIdProvider: FC = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('modelId');
  const modelId = id ? parseInt(id, 10) : getModelId();

  if (modelId) {
    setModelId(modelId);
  }

  return (
    <ModelIdContext.Provider value={modelId} >
      {children}
    </ModelIdContext.Provider>
  )
}
