import s from '../../style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useEffect } from 'preact/hooks';
import { Placeholder, Post, ScrollView } from 'ui';
import { inclineUserName } from '../../../../utils';
import { useModel, usePosts, useTranslator } from '../../../../hooks';
import { GENITIVE } from 'lvovich/lib/inclineRules';

export const Posts: FC = () => {
  const t = useTranslator();
  const { posts, loadPosts, loading } = usePosts();
  const model = useModel();

  useEffect(() => {
    if (posts.length === 0) {
      loadPosts(true);
    }
  }, []);

  if (!loading && !posts.length) {
    return (
      <Placeholder
        wrapped
        className={s.mediaPlaceholder}
        iconName="regret"
        title={t('media_free_empty_title')}
        description={t('media_free_empty_description', { name: inclineUserName(model, GENITIVE).first})}
      />
    )
  }

  return (
    <ScrollView isLoadingMore={loading} canLoadMore={!loading} onLoadMore={() => loadPosts()}>
      {posts.map((p) => (
        <Post key={p.id} post={p} />
      ))}
    </ScrollView>
  )
}
