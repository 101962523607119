import { h, FunctionComponent as FC } from 'preact';
import { createContext } from 'preact/compat';
import { useEffect, useState, useCallback } from 'preact/hooks';


import i18n, { Scope, TranslateOptions } from 'i18n-js';

import { pluralizeForRussian } from './helper';
import ru from './locales/ru.json';
import en from './locales/en.json';
import { LanguageTags } from '../types';

const translations: {[key: string]: object} = {
  ru,
  en,
};

export function getLanguage(): string {
  return localStorage.getItem('lang') || Telegram.WebApp.initDataUnsafe.user?.language_code || 'en';
}

export type TranslatorFunc = (scope: Scope, options?: TranslateOptions) => string;

export const TranslatorContext = createContext({
  t: (scope: Scope, options?: TranslateOptions) => i18n.t(scope, options),
  lang: getLanguage(),
});

export const TranslatorProvider: FC = ({ children }) => {
  const [hasLangChanged, setHasLangChanged] = useState(false);

  const t: TranslatorFunc = useCallback(
    (scope, options?) => i18n.t(scope, options),
    [hasLangChanged],
  );

  const setI18nConfig = useCallback(() => {
    const languageTag = getLanguage();

    let keys = translations[languageTag];
    if (languageTag !== LanguageTags.en) {
      keys = {
        ...translations[LanguageTags.en],
        ...keys,
      };
    }

    i18n.translations = {
      [languageTag]: keys,
    };
    i18n.locale = languageTag;

    setHasLangChanged((prev) => !prev);
  }, []);

  useEffect(() => {
    setI18nConfig();
    addEventListener('langChange', setI18nConfig);
    return () => removeEventListener('langChange', setI18nConfig);
  }, []);

  return (
    <TranslatorContext.Provider value={{ t, lang: getLanguage() }}>
      {children}
    </TranslatorContext.Provider>
  );
};

i18n.pluralization.ru = pluralizeForRussian;
