import style from './style.css';

import { h, FunctionalComponent as FC, Fragment } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';

import {Block, Button, List, ListItem, Placeholder, PoweredBy, Screen} from 'ui';
import { Api } from 'api';
import { useModel, useTranslator } from '../../hooks';
import {format, inclineUserName, roundNumber, showAlert, showConfirm, statReachGoal} from '../../utils';
import { useRouter } from 'react-router5';
import { GENITIVE } from 'lvovich/lib/inclineRules';

import CrownIcon from '../../assets/icons/crown.svg';
import PriceIcon from '../../assets/icons/price.svg';
import DateIcon from '../../assets/icons/date.svg';
import RenewsIcon from '../../assets/icons/renews.svg';
import CancelIcon from '../../assets/icons/cancel.svg';
import { SubscriptionInfo } from '../../types';
import { routes } from '../../navigations/routes';

export const SponsorScreen: FC = () => {
  const model = useModel();
  const router = useRouter();
  const t = useTranslator();
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState<SubscriptionInfo>(null!);

  useEffect(() => {
    statReachGoal('view_subscription')
  }, []);

  const handleCancelSubscription = useCallback(async () => {
    if (await showConfirm(t('sponsor_confirm_cancel_subscription'))) {
      try {
        statReachGoal('subscription_cancel');
        await Api.post(`/balance/subscription/${subscription.id}/cancel`);
        Telegram.WebApp.close();
      } catch (e: any) {
        await showAlert(e.message);
      }
    }
  } , [subscription]);

  const handleViewCollection = useCallback(() => {
    router.navigate(routes.MEDIA, { section: 'premium' })
  }, [router]);

  const getSubscription = useCallback(() => {
    setLoading(true);
    statReachGoal('subscribe_click');
    Api.get<any, any>(`/balance/subscriptions`).then(({ subscriptions }) => {
      setSubscription(subscriptions.find((s: any) => s.authorId === model.id));
      statReachGoal('subscribe_success');
    }).catch(e => {
      showAlert(e.message)
      statReachGoal('subscribe_fail');
    }).finally(() => {
      setLoading(false);
    });
  }, [model])

  useEffect( () => {
    getSubscription();
    Telegram.WebApp.expand();
  }, [getSubscription])

  return (
    <Screen loading={loading} className={style.sponsor}>
      <Block>
        <Placeholder
          iconName="crown"
          title={t(`sponsor_title`, { name: inclineUserName(model, GENITIVE).full })}
          description={t('sponsor_description', { name: inclineUserName(model, GENITIVE).full })}
        />
      </Block>
      {subscription && (
        <Fragment>
          <Block>
            <List>
              <ListItem
                icon={<CrownIcon />}
                title={t('sponsor_property_subscription_label')}
                postfix={
                  subscription.isRecurrent ?
                    t('sponsor_property_subscription_value_active') :
                    t('sponsor_property_subscription_value_canceled')
                }
              />
              <ListItem
                icon={<PriceIcon />}
                title={t('sponsor_property_price_label')}
                postfix={`€${roundNumber(subscription.price)}`}
              />
              <ListItem
                icon={<DateIcon />}
                title={t('sponsor_property_duration_label')}
                postfix={t('sponsor_property_duration_value')}
              />
              <ListItem
                icon={subscription.isRecurrent ? <RenewsIcon /> : <CancelIcon />}
                title={
                  subscription.isRecurrent ?
                    t('sponsor_property_renew_label') :
                    t('sponsor_property_expires_label')
                }
                postfix={format(subscription.activeAt * 1000, 'EEE, d MMM yyyy')}
              />
            </List>
          </Block>
          <Block>
            <Button mainButton onClick={handleViewCollection} text={t('sponsor_button_view_collection')} />
            {subscription.isRecurrent && (
              <Button mode="destructive" onClick={handleCancelSubscription}>
                {t('sponsor_button_cancel_subscription')}
              </Button>
            )}
          </Block>
          <PoweredBy footer />
        </Fragment>
      )}
    </Screen>
  );
}
