import { useCallback, useContext } from 'preact/hooks';
import { MainContext } from '../contexts/mainContext';
import { Api } from 'api';
import { useModelId } from './useModel';
import { Subscription } from '../types';
import amplitude from 'amplitude-js';

export const useMainState = () => {
  const { mainState, setMainState } = useContext(MainContext);
  const modelId = useModelId();

  const initMainState = useCallback(() => {
    return Promise.all([
      Api.get<null, any>('/main/init'),
      Api.get<null, Subscription>(`/balance/subscription/author/${modelId}`),
    ]).then(([init, s]) => {
      const subscriptions = Object.keys(init.decryptKeys).map(Number);
      amplitude.getInstance().setUserId(`${init.user.id}`);

      setMainState({
        ...init,
        subscription: s,
        subscriptions,
        hasSubscription: subscriptions.includes(modelId),
        payErrorCode: null,
      });
    }).catch((e) => {
      console.error(e);
    });
  }, [modelId, setMainState]);

  return {
    mainState,
    setMainState,
    initMainState
  }
}
