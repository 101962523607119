import axios from 'axios';
import axiosRetry from 'axios-retry';

import { getToken } from '../utils';
import {getLanguage} from "../translation";

const HOST = window.location.host;
const PREPROD = HOST.includes('tonplace.host');
const STAGING = HOST.split('.')[0] === 'tg-staging';

export const API_HOST = window.localStorage.getItem('apiHost') || STAGING ? 'api.tonplace.host' : PREPROD ? 'preprod.ton.place' : 'api.ton.place';
export const API_URL = `https://${API_HOST}`;

export const Api = axios.create({
  baseURL: API_URL,
  // baseURL: 'https://preprod.ton.place',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': getLanguage(),
  },
});

axiosRetry(Api, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
});

export interface ApiError {
  message: string;
}

Api.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `TgAuth ${getToken()}`,
        Timezone: new Date().getTimezoneOffset(),
      },
    };
  },
  (error) => {
    console.warn('error', error);
    return Promise.reject(error);
  },
);



Api.interceptors.response.use(
  (response) => {
    if (['document', 'text'].indexOf(response.config.responseType as string) > -1) {
      return response.data;
    }

    return response.status === 200
      ? Promise.resolve(response.data)
      : Promise.reject(response.data);
  },
  (error) => {
    console.error(error);
    return Promise.reject(error.response ? error.response.data : error);
  }
);
