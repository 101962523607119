import s from "./style.css";
import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { Block, Button, Screen } from 'ui';
import { getToken } from '../../utils';
import { useDebug, useModelId } from '../../hooks';
import { hasHistoryBack } from '../../navigations/router';
import { getLanguage } from '../../translation';

export const DebugScreen = () => {
  const modelId = useModelId();
  const { setDebug } = useDebug();

  const handleCancelDebug = () => setDebug(false);

  useEffect(() => {
    const { WebApp } = Telegram;
    WebApp.expand();
    document.body.classList.add('can-select');
    WebApp.BackButton.onClick(handleCancelDebug);
    WebApp.BackButton.show();
    WebApp.setHeaderColor('secondary_bg_color');
    WebApp.setBackgroundColor('secondary_bg_color');
    return () => {
      document.body.classList.remove('can-select');
      WebApp.BackButton.offClick(handleCancelDebug);
      WebApp.BackButton.hide();
      WebApp.setHeaderColor('secondary_bg_color');
      WebApp.setBackgroundColor('secondary_bg_color');
    }
  }, [handleCancelDebug]);

  return (
    <Screen>
      <Block>
        <div>
          <div className={s.key}>mode:</div>
          <div className={s.value}>{location.host === 'tg.ton.place' ? (
              <b style={{ color: '#00aa00'}}>Production</b>
            ) : (
              <b style={{ color: '#dd8e08'}}>Staging</b>
            )}
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>lang:</div>
          <div className={s.value}>{getLanguage()}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>platform:</div>
          <div className={s.value}>{Telegram.WebApp.platform}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>hasHistoryBack:</div>
          <div className={s.value}>{hasHistoryBack() ? 'true' : 'false'}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>WebApp version:</div>
          <div className={s.value}>{Telegram.WebApp.version}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>modelId:</div>
          <div className={s.value}>{modelId}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>token:</div>
          <div className={s.value}>{getToken()}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>initDataUnsafe:</div>
          <div className={s.value}>{JSON.stringify(Telegram.WebApp.initDataUnsafe, null, 2)}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>WebApp:</div>
          <div className={s.value}>{JSON.stringify(Telegram.WebApp, null, 2)}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>URL:</div>
          <div className={s.value}>{window.location.href}</div>
        </div>
        <div className={s.row}>
          <div className={s.key}>initURL:</div>
          <div className={s.value}>{(window as any).initUrl}</div>
        </div>
      </Block>
      <Block>
        <ul>
          {[
            'https://t.me/DoginaMilBot/DoginaMilBot',
            'https://t.me/stage_ton_bot/myapp',
            't.me/DoginaMilBot/DoginaMilBot',
            'tg://t.me/DoginaMilBot/DoginaMilBot',
            'tg://resolve?domain=DoginaMilBot'
          ].map(url => (<li key={url}><a href={url}>{url}</a></li>))}
        </ul>
      </Block>
      <Block>
        <Button mode="secondary" onClick={() => localStorage.clear()}>Clear storage</Button>
        <Button mainButton onClick={handleCancelDebug}>Close debug mode</Button>
      </Block>
    </Screen>
  )
}
