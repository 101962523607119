import style from './style.css'
import { h, FunctionalComponent as FC } from 'preact'
import cn from 'classnames'

export const Loader: FC<LoaderProps> = ({
  wrapped,
  className,
  light = Telegram.WebApp.colorScheme === 'dark'
}) => {
  const render = () => (
    <div className={cn(style.loader, {
      [style.light]: light,
      [className || '']: !wrapped,
    })} />
  );

  if (wrapped) {
    return (
      <div className={cn(style.wrapper, className)}>
        {render()}
      </div>
    );
  }
  return render();
}
