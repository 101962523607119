import { h, createContext, FunctionalComponent as FC } from 'preact';
import { useState } from 'preact/hooks';

export const DebugContext = createContext<{
  debug: boolean;
  setDebug: (debug: boolean) => void;
}>({
  debug: true,
  setDebug: () => {},
});

export const DebugProvider: FC = ({ children }) => {
  const [debug, setDebug] = useState(false);
  return (
    <DebugContext.Provider value={{ debug, setDebug }} >
      {children}
    </DebugContext.Provider>
  )
}
