import { useCallback, useContext } from 'preact/hooks';
import { MediaContext, MediaState } from '../contexts/mediaContext';
import { Api } from 'api';
import { Message } from '../types';
import { Post, PostType } from '../types/Post';
import { useModel, useModelId } from './useModel';


export const usePurchases = () => {
  const model = useModel();
  const {mediaState, setMediaState} = useContext(MediaContext);

  const setPurchasesState = useCallback((state: Partial<MediaState['purchase']>) => {
    setMediaState({
      ...mediaState,
      purchase: {
        ...mediaState.purchase,
        ...state,
      },
    });
  }, [mediaState, setMediaState]);

  const loadPurchases = useCallback(async (reload = false) => {
    if (!mediaState.purchase.startFrom && !reload) {
      return false;
    }
    setPurchasesState({
      loading: true,
    });
    try {
      const { nextFrom, purchases } = await Api.get<null, {
        nextFrom: number | string,
        purchases: Message[],
      }>('/purchases', {
        params: {
          senderId: model.id,
          startFrom: reload ? 0 : mediaState.purchase.startFrom,
        }
      });

      setPurchasesState({
        startFrom: +nextFrom,
        loading: false,
        purchases: reload ? purchases : [
          ...mediaState.purchase.purchases,
          ...purchases,
        ],
      });
    } catch (e) {
      setPurchasesState({
        loading: false,
      });
    }
  }, [mediaState.purchase, setPurchasesState]);

  return {
    purchases: mediaState.purchase.purchases,
    loading: mediaState.purchase.loading,
    loadPurchases,
  }
}

export const usePosts = () => {
  const {mediaState, setMediaState} = useContext(MediaContext);
  const model = useModel();

  const setPostsState = useCallback((state: Partial<MediaState['posts']>) => {
    setMediaState({
      ...mediaState,
      posts: {
        ...mediaState.posts,
        ...state,
      },
    });
  }, [mediaState, setMediaState]);

  const loadPosts = useCallback(async (reload = false) => {
    if (!mediaState.posts.startFrom && !reload) {
      return false;
    }
    setPostsState({
      loading: true,
    });
    try {
      const { nextFrom, posts } = await Api.post<null, {
        nextFrom: number | string,
        posts: Post[],
      }>(`/profile/${model.id}/posts`, {
        onlyFree: true,
        startFrom: reload ? 0 : mediaState.posts.startFrom,
      });

      setPostsState({
        startFrom: +nextFrom,
        loading: false,
        posts: [
          ...(reload ? [] : mediaState.posts.posts),
          ...posts
        ],
      });
    } catch (e) {
      setPostsState({
        loading: false,
      });
    }
  }, [mediaState.posts, setPostsState]);

  return {
    posts: mediaState.posts.posts,
    loading: mediaState.posts.loading,
    loadPosts,
  }
}

export const useSponsors = () => {
  const {mediaState, setMediaState} = useContext(MediaContext);
  const model = useModel();

  const setPostsState = useCallback((state: Partial<MediaState['sponsors']>) => {
    setMediaState({
      ...mediaState,
      sponsors: {
        ...mediaState.sponsors,
        ...state,
      },
    });
  }, [mediaState, setMediaState]);

  const loadPosts = useCallback(async (reload = false) => {
    if (!mediaState.sponsors.startFrom && !reload) {
      return false;
    }
    setPostsState({
      loading: true,
    });
    try {
      const { nextFrom, posts, feed } = await Api.post<null, {
        nextFrom: number | string,
        posts: Post[],
        feed: { object: number, type: string }[]
      }>(`/profile/${model.id}/posts`, {
        onlySponsored: false,
        startFrom: reload ? 0 : mediaState.sponsors.startFrom,
      });

      setPostsState({
        startFrom: +nextFrom,
        loading: false,
        posts: [
          ...(reload ? [] : mediaState.posts.posts),
          ...posts,
        ],
      });
    } catch (e) {
      setPostsState({
        loading: false,
      });
    }
  }, [mediaState.sponsors, setPostsState]);

  return {
    posts: mediaState.sponsors.posts,
    loading: mediaState.sponsors.loading,
    loadPosts,
  }
}
