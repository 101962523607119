import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { Block, Screen, SegmentedControl } from 'ui';
import { useCallback, useEffect } from 'preact/hooks';
import { Posts } from './components/posts/posts';
import { Purchases } from './components/purchases/purchases';
import { Sponsors } from './components/sponsors/purchases';
import { useRoute } from 'react-router5';
import { routes } from '../../navigations/routes';
import { useTranslator } from '../../hooks';
import {statReachGoal} from "../../utils";


export const MediaScreen: FC = () => {
  const { router, route } = useRoute();
  const t = useTranslator();
  const section = route.params.section || 'purchases';

  useEffect(() => {
    statReachGoal(`view_media_${section}`);
  }, [section]);

  const handleChange = useCallback((section: string) => {
    router.navigate(routes.MEDIA, {
      section,
    }, { replace: true });
  }, []);

  useEffect(() => {
    Telegram.WebApp.expand();
  })

  return (
    <Screen>
      <div className={s.mediaHeader}>
        <SegmentedControl onChange={handleChange} options={[
          {
            label: t('media_switch_purchased'),
            value: 'purchases',
          },
          {
            label: t('media_switch_free'),
            value: 'posts',
          },
          {
            label: t('media_switch_premium'),
            value: 'premium'
          },
        ]} defaultValue={section} />
      </div>
      <div className={s.mediaContent}>
        {section === 'posts' ? <Posts /> : section === 'purchases' ? <Purchases /> : <Sponsors />}
      </div>
    </Screen>
  );
}
