import s from './style.css';

import { FunctionalComponent as FC, h } from 'preact';
import cn from 'classnames';

import { AttachmentProps } from './Attachment.interface';
import { AttachType, AttachVideo, VideoSource } from '../../../types/Message';
import { aesDecrypt } from '../../../utils';

export const Attachment: FC<AttachmentProps> = ({ attach, decryptKey }) => {
  if (attach.type === AttachType.PHOTO && attach.photo) {
    const { photo } = attach;

    const src = ((photo.photoMediumEncrypted && decryptKey) ?
      aesDecrypt(photo.photoMediumEncrypted!, decryptKey) :
      photo.photoMedium) || '';

    return (
      <div
        className={cn(s.Attach, s.AttachPhoto)}
        style={{
          backgroundImage: `url(${photo.thumbnail})`,
          '--ratio': photo.height / photo.width
        }}
      >
        <img
          className={s.AttachPhotoImage}
          src={src}
        />
      </div>
    )
  } else if (attach.type === AttachType.VIDEO && attach.video) {

    let src = attach.video.source;
    let json  = attach.video.source;

    if (src && src[0] !== '[' && decryptKey) {
      json = aesDecrypt(src, decryptKey as string) as string;
    }
    try {
      const object = JSON.parse(json);
      src = object[0].path;
    } catch (e) {
      console.error(e);
    }

    return (
      <div
        className={cn(s.Attach, s.AttachVideo)}
      >
        <img src={attach.video.photoMedium} alt="" />
        <video
          className={s.AttachVideoNative}
          controls
          poster={attach.video.photoMedium}
          preload="auto"
          disablePictureInPicture
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    )
  }
  return null;
}
