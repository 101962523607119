import style from './style.css';

import cn from "classnames";
import { h, FunctionalComponent as FC } from 'preact';

import VisaIcon from "../../../assets/icons/visa.svg";
import MastercardIcon from "../../../assets/icons/mastercard.svg";
import MirIcon from "../../../assets/icons/mir.svg";
import DefaultIcon from "../../../assets/icons/card.svg";

import { CardIconProps } from './CardIcon.interface';


export const CardIcon: FC<CardIconProps> = ({type, className}) => {
  return (
    <div className={cn(style.icon, className)}>
      {type === 'visa' ?
        <VisaIcon /> : type === 'mastercard' ?
          <MastercardIcon />: type === 'mir' ?
            <MirIcon /> : <DefaultIcon />}
    </div>
  );
}
