import style from './style.css'

import { h, FunctionalComponent as FC, JSX } from 'preact';
import { InputProps } from './Input.interface';
import cn from 'classnames';
import { formatInputAmount, showAlert } from '../../../utils';
import { useEffect, useRef } from 'preact/hooks';

export const Input: FC<InputProps> = ({
  type = 'text',
  value = '',
  prefix,
  description = '',
  placeholder = '',
  onChange,
  rows = 3,
  autoFocus = false,
  multiline = false,
}) => {
  const inputRef = useRef<any>(null);

  const handleFocus = () => {
    Telegram.WebApp.expand();
  }

  const handleChange = (e: JSX.TargetedEvent<(HTMLInputElement | HTMLTextAreaElement), Event>) => {
    const value = e.currentTarget.value.replace('-', '');
    e.currentTarget.value = value;
    onChange(value);
  };

  // useEffect(() => {
  //   if (autoFocus && inputRef.current) {
  //     setTimeout(() => {
  //       inputRef.current.focus();
  //     }, 0);
  //   }
  // }, [autoFocus, inputRef]);

  return (
    <div className={style.input}>
      <div className={style.block}>
        {!!prefix && <div className={style.prefix}>{prefix}</div>}
        {multiline ? (
          <textarea
            ref={inputRef}
            placeholder={placeholder}
            rows={rows}
            onFocus={handleFocus}
            onInput={handleChange}
            className={cn(style.native, style.textarea)}
            value={value}
          />
        ) : (
          <input
            min="1"
            max="500"
            onFocus={handleFocus}
            pattern={type === 'number' ? '[0-9]*' : undefined}
            inputMode={type === 'number' ? 'numeric' : undefined}
            step={type === 'number' ? '0.01' : undefined}
            ref={inputRef}
            autoFocus={autoFocus}
            placeholder={placeholder}
            onInput={handleChange}
            type={type}
            className={style.native}
            value={value}
          />
        )}
      </div>
      {!!description && <div className={style.description}>{description}</div>}
    </div>
  );

}
