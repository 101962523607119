import s from '../../style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useCallback, useEffect } from 'preact/hooks';
import { Placeholder, Post, ScrollView, Button } from 'ui';
import { useMainState, useModel, usePurchases, useTranslator } from '../../../../hooks/';
import { inclineUserName } from '../../../../utils';

export const Purchases: FC = () => {
  const t = useTranslator();
  const { purchases, loadPurchases, loading } = usePurchases();
  const model = useModel();
  const { mainState: {
    hasSubscription
  }} = useMainState();

  useEffect(() => {
    if (purchases.length === 0) {
      loadPurchases(true);
    }
  }, []);

  const handleTextMessage = useCallback(() => {
    Telegram.WebApp.close();
  }, []);

  if (!loading && !purchases.length) {
    return (
      <Placeholder
        wrapped
        className={s.mediaPlaceholder}
        iconName="message"
        title={t('media_published_empty_title')}
        description={t('media_published_empty_description', { name: inclineUserName(model).first })}
        action={
          <Button mainButton onClick={handleTextMessage} text={t('media_published_button')} />
        }
      />
    )
  }

  return (
    <ScrollView isLoadingMore={loading} canLoadMore={!loading} onLoadMore={() => loadPurchases()}>
      {purchases.map((p) => (
        <Post key={p.id} post={p} />
      ))}
    </ScrollView>
  )
}
