import s from './style.css'

import { h, FunctionComponent as FC } from 'preact'
import { TitleProps } from './Title.interface';
import { useCallback } from 'preact/hooks';

export const Title: FC<TitleProps> = ({
  title,
  buttonText,
  buttonOnClick,
}) => {

  const handleButtonClick = useCallback(() => {
    Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    buttonOnClick && buttonOnClick();
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.title}>{title}</div>
      {buttonText && <div className={s.right} onClick={handleButtonClick}>{buttonText}</div>}
    </div>
  )
}
