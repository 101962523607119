import s from './style.css';

import { Fragment, h } from "preact";
import { Block, Screen, Button } from 'ui';
import { useMainState, useTranslator, useWikiLinkCallback } from '../../hooks';
import { useRoute, useRouter } from 'react-router5';
import { useCallback } from 'preact/hooks';
import { ActionEnum } from '../../types';
import { routes } from '../../navigations/routes';

import IcBan from "../../assets/icons/ban.svg";
import IcNewTab from "../../assets/icons/newtab.svg";

export const TopUpFailedScreen = () => {
  const t = useTranslator();
  const { route }  = useRoute();
  const router  = useRouter();
  const { objectId, action } = route.params;
  const { mainState } = useMainState();
  const wikiSupportLink = useWikiLinkCallback('/general-information/customer-support')

  const handleTryAgain = useCallback(() => {
    if (action === ActionEnum.SUBSCRIPTION) {
      router.navigate(routes.PAY, {
        action,
      }, {
        replace: true,
        transition: false
      })
    } else if (action === ActionEnum.BUY_MESSAGE) {
      router.navigate(routes.PAY, {
        action,
        objectId,
      }, {
        replace: true,
        transition: false
      })
    } else if (action === ActionEnum.TIPS) {
      router.navigate(routes.BALANCE, {
        action: ActionEnum.TIPS,
        requireTopUpAmount: objectId,
      },{
        replace: true,
        transition: false
      })
    } else if (action === ActionEnum.BUY_TG_POST) {
      router.navigate(routes.BALANCE, {
        action: ActionEnum.BUY_TG_POST,
        requireTopUpAmount: objectId,
      },{
        replace: true,
        transition: false
      })
    } else {
      router.navigate(routes.BALANCE, {},{
        replace: true,
        transition: false
      })
    }
    // TODO tips
  }, [action, objectId, router]);

  return (
    <Screen>
      <Block flex className={s.topUpFailedWrapper}>
        <div className={s.topUpFailedContent}>
          <IcBan />
          <div className={s.topUpFailedTitle}>{t('top_up_failed_title')}</div>
          {mainState.payErrorCode ? (
            <div className={s.topUpFailedTextBlock}>{t(`top_up_failed_error_code_${mainState.payErrorCode}`)} ({mainState.payErrorCode})</div>
          ) : (
            <Fragment>
              <div className={s.topUpFailedTextBlock}>
                <p>{t('top_up_failed_description')}</p>
                <ol>
                  <li>{t('top_up_failed_item_1')}</li>
                  <li>{t('top_up_failed_item_2')}</li>
                  <li>{t('top_up_failed_item_3')}</li>
                </ol>
              </div>
            </Fragment>
          )}
        </div>
      </Block>
      <Block>
        <Button mainButton onClick={handleTryAgain}>
          {t('top_up_failed_button_try_again')}
        </Button>
        <Button
          className={s.topUpFailedButtonSupport}
          mainButton
          mode="secondary"
          onClick={wikiSupportLink}
        >
          {t('top_up_failed_button_support')}
          <IcNewTab />
        </Button>
      </Block>
    </Screen>
  );
};
