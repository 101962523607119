import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useEffect } from 'preact/hooks';
import { Suspense, lazy, useState } from 'preact/compat';
import cn from 'classnames';

import { LottieIconProps } from './LottieIcon.interface';
const Lottie = lazy(() => import('react-lottie'));

export const LottieIcon: FC<LottieIconProps> = ({
  size = 100,
  name = '',
  className,
  onClick,
}) => {
  const [animationData, setAnimationData] = useState<any | null>(null);
  useEffect(() => {
    import(`../../../assets/lottie/${name}.json`).then(setAnimationData);
  }, [name]);
  return (
    <div onClick={onClick} className={cn(s.lottieIcon, className)} style={{
      width: size,
      height: size,
    }}>
      <Suspense fallback={<div />}>
        { animationData && (
          <Lottie
            isClickToPauseDisabled={true}
            ariaRole="img"
            options={{
              animationData,
              autoplay: true,
              loop: false,
            }}
            height={size}
            width={size}
          />
        )}
      </Suspense>
    </div>
  )
}
