import {useCallback, useEffect} from 'preact/hooks';
import { initWebSocket, wsEvent } from '../api/realtime';
import { useMainState } from './useMainState';
import { useRouter } from 'react-router5';
import { routes } from '../navigations/routes';
import { useModelId } from './useModel';

export const useRealTime = () => {
  const router = useRouter();
  const modelId = useModelId();
  const {
    setMainState,
    mainState,
  } = useMainState();

  useEffect(() => {
    const closeConnection = initWebSocket();

    return () => {
      closeConnection();
    }
  }, [])

  useEffect(() => {
    wsEvent.on('connect', () => {
      console.log('ws connect');
    });

    wsEvent.on('eur_balance_top_up', body => {
      setMainState({
        eurBalance: body.balance,
      });
    });

    wsEvent.on('pay_error', ({type, code}) => {
      setMainState({
        card: null,
        payErrorCode: code,
      });
      router.navigate(routes.TOP_UP_FAILED,
        type === 'subscription' ? {
          type: 'subscription',
          modelId,
          objectId: modelId,
        } : {});

      // TODO: by message
    });

    wsEvent.on('pay_action_required', ({ url }) => {
      location.href = url;
    });

    return () => {
      wsEvent.removeAllListeners();
    }
  }, [mainState, modelId, router, setMainState])
}
