import { h, FunctionalComponent as FC } from 'preact';
import { useRoute, useRouter } from 'react-router5';

import { inclineUserName, roundNumber, showAlert } from '../../utils';
import { GENITIVE } from 'lvovich/lib/inclineRules';
import { Button, Placeholder } from 'ui';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { Api } from 'api';
import { useMainState, useModel, useTranslator } from '../../hooks';
import { routes } from '../../navigations/routes';
import { PremiumPlaceholderProps } from './PremiumPlaceholder.interface';
import { wsEvent } from "../../api/realtime";
import './PremiumPlaceholder.css';

export const PremiumPlaceholder: FC<PremiumPlaceholderProps> = ({ className }) => {
  const router = useRouter();
  const { route } = useRoute();
  const { objectId, action, skipBalance } = route.params;
  const t = useTranslator();
  const [payLoader, setPayLoader] = useState( false);
  const model = useModel();
  const { mainState: {
    eurBalance,
    subscription
  }} = useMainState();

  const price = useMemo(() => {
    let res = subscription.price;
    if (subscription.subscriptionDiscount > 0) {
      res = res - res * subscription.subscriptionDiscount / 100;
    }
    return res;
  }, [subscription]);

  const canPay = price <= eurBalance;

  const handleTopUp = useCallback(() => {
    // router.navigate(routes.BALANCE, {
    //   action: ActionEnum.SUBSCRIPTION,
    //   objectId: model.id,
    //   requireTopUpAmount: subscription.price - eurBalance
    // })
    setPayLoader(true);
    window.localStorage.setItem('autoPay', '1');
    Api.post("/pay/top_up", {
      amount: price,
      skipAutoPay: false,
      modelId: model.id,
      target: "sponsor_subscription",
      targetObject: parseInt(objectId, 10),
    }).then((data: any) => {
      if (data.token) {
        router.navigate(routes.PAY_FORM, {
          token: data.token
        })
      } else if (data.status == "sent_payment") {
        if (objectId && action) {
          wsEvent.once("eur_balance_top_up", () => {
            router.navigate(routes.PAY, {
              action,
              objectId,
            })
            setPayLoader(false);
          })
        } else {
          Telegram.WebApp.close();
        }
      }
    }).catch(() => {
      setPayLoader(false);
    })
  } , [router, model.id, subscription.price, objectId, action, price]);

  const handleSubscribe = useCallback(() => {
    setPayLoader(true);
    Api.post('/balance/subscription/buy', {
      authorId: model.id,
      categoryId: 3,
    }).then(() => {
      Telegram.WebApp.close();
    }).catch((e) => {
      showAlert(e.message)
    }).finally(() => {
      setPayLoader(false);
    })
  } , [model.id]);

  useEffect(() => {
    if (window.localStorage.getItem('autoPay') && canPay) {
      handleSubscribe();
    }
    window.localStorage.removeItem('autoPay');
  }, [canPay, handleSubscribe]);

  return (
    <div style={{ height: '100%' }}>
      <Placeholder
        wrapped
        className={className}
        iconName="crown"
        title={t(`sponsor_title`, { name: inclineUserName(model, GENITIVE).full })}
        description={t('sponsor_description', { name: inclineUserName(model, GENITIVE).full })}
        action={
          <Button
            progress={payLoader}
            mainButton
            onClick={canPay ? handleSubscribe : handleTopUp}
          >
            {t('sponsor_button_buy', { amount: roundNumber(price) })}
          </Button>
        }
      />
      <div className="PremiumPlaceholder_payInfoWrap">
        <div className="PremiumPlaceholder_payInfo">
          {t('sponsor_subscription_pay_info')} {subscription.subscriptionDiscount > 0 && t('sponsor_subscription_discount', {
          price: subscription.price,
        })}
        </div>
      </div>
    </div>
  );
}
