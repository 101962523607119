import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import cn from 'classnames';

import { Loader } from '../Loader/Loader';
import { useDebug } from '../../../hooks/useDebug';

export const Screen: FC<ScreenProps> = ({
  children,
  loading,
  className,
  viewport = false
}) => {
  const { tapDebugHandler } = useDebug();

  return (
    <div onClick={tapDebugHandler} className={cn(s.screen, className, {
      [s.screenViewport]: viewport
    })}>
      {loading ? <Loader wrapped /> : children}
    </div>
  )
}

export const Block: FC<BlockProps> = ({ children, className, flex }) => {
  return (
    <div className={cn(s.block, className, {
      [s.blockFlex]: flex
    })}>{children}</div>
  )
}
