import s from './style.css';

import { h, FunctionalComponent as FC } from 'preact';
import { useCallback } from 'preact/hooks';
import cn from 'classnames';
import router from '../../../navigations/router';

import { ListItemProps, ListProps } from './List.interface';
import SvgChevron from '../../../assets/icons/chevron.svg';


export const List: FC<ListProps> = ({ children, mode = 'default'}) => {
  return (
    <div className={cn(s.list, {
      [s.listCompact]: mode === 'default'
    })}>{children}</div>
  )
}

export const ListItem: FC<ListItemProps> = (props) => {
  const handleClick = useCallback(() => {
    if (props.route || props.onClick) {
      Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    }
    if (props.route) {
      router.navigate(props.route);
    } else if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  const hasChevron = props.route || props.onClick;

  return (
    <div className={cn(s.listItem, {
      [s.listItemActive]: hasChevron
    })} onClick={handleClick}>
      <div className={s.listItemIcon} onClick={handleClick}>
        {props.icon}
      </div>
      <div className={s.listItemContent}>
        <div className={s.listItemContentTitle}>{props.title}</div>
        {props.description && <div className={s.listItemContentDescription}>{props.description}</div>}
      </div>
      {props.postfix && (
        <div className={s.listItemPostfix}>{props.postfix}</div>
      )}
      {hasChevron && (
        <div className={s.listItemChevron}>
          <SvgChevron />
        </div>
      )}
    </div>
  )
}
