import { useEffect, useState } from 'preact/compat';
import {
  differenceInYears,
  formatDistanceToNowStrict,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
} from 'date-fns';

import { dateFnsLocale, format } from '../utils/index';

export function useDate(ts: number): string {
  // const t = useTranslator();
  // const currentLang = useCurrentLang();
  const [now, setNow] = useState(new Date());
  const date = new Date(ts * 1000);
  const updateNow = () => {
    setNow(new Date());
  };

  useEffect(() => {
    if (differenceInHours(now, date)) {
      return;
    }
    const interval = setInterval(updateNow, 15 * 1000);
    return () => clearInterval(interval);
  }, [ts]);

  if (!ts) {
    return '';
  }

  const locale = dateFnsLocale.en;
  const diffInDays = differenceInDays(now, date);

  if (differenceInSeconds(now, date) < 5) {
    return 'now';
  } else if (diffInDays < 1) {
    return formatDistanceToNowStrict(date, { addSuffix: true, locale });
  } else if (diffInDays < 7) {
    return `${formatDistanceToNowStrict(date, { addSuffix: true, locale })} in ${format(date, 'HH:mm')}`;
  } else if (differenceInYears(now, date) === 0) {
    return `${format(date, 'dd MMM', { locale })} in ${format(
      date,
      'HH:mm',
    )}`;
  } else {
    return `${format(date, 'dd MMM yyyy', { locale })} in ${format(
      date,
      'HH:mm',
    )}`;
  }
}

export function useDateShort(ts: number): string {
  const now = new Date();
  const date = new Date(ts * 1000);

  const seconds = differenceInSeconds(now, date);
  const minutes = differenceInMinutes(now, date);
  const hours = differenceInHours(now, date);
  const days = differenceInDays(now, date);

  if (seconds <= 3) {
    return '';
  } else if (seconds <= 60) {
    return `${seconds}s`;
  } else if (minutes <= 60) {
    return `${minutes}m`;
  } else if (hours <= 24) {
    return `${hours}h`;
  } else {
    return `${days}d`;
  }
}
